/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import image1 from '../../images/iffco-1.jpg';
import image2 from '../../images/iffco-2.jpg';
import image7 from '../../images/iffco-7.jpg';
import image11 from '../../images/iffco-11.jpg';
import image12 from '../../images/iffco-12.jpg';
import image13 from '../../images/iffco-13.jpg';
import image15 from '../../images/iffco-15.jpg';
import './PictureDisplayer.css';

export default function PictureDisplayer() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    { src: image1, key: 1 },
    { src: image2, key: 2 },
    { src: image7, key: 7 },
    { src: image11, key: 11 },
    { src: image12, key: 12 },
    { src: image13, key: 13 },
    { src: image15, key: 15 },
  ];

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="main-container-pic-displayer-footer">
      <div className="first-line-pic-container-footer">
        {images.map((image, index) => {
          return (
            <div key={image.key} className="images-first-line-container-pic-displayer">
              <img
                src={image.src}
                onClick={() => openImageViewer(index)}
                width="85px"
                height="85px"
                key={image.src}
                style={{ margin: '2px' }}
                alt=""
              />
            </div>
          );
        })}
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images.map((i) => i.src)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        />
      )}
    </div>
  );
}
